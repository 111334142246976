<template>
  <div>
    <!--    <div v-if="$screenIsSmall()">-->
    <!--      <div id="datos-fotos">-->
    <!--        <v-form-->
    <!--          ref="form"-->
    <!--          v-model="valid"-->
    <!--          lazy-validation-->
    <!--        >-->
    <!--          <v-main>-->
    <!--            <v-row>-->
    <!--              <v-col-->
    <!--                align="center"-->
    <!--                justify="center"-->
    <!--              >-->
    <!--                <div>-->
    <!--                  <h3>-->
    <!--                    En las próximas 24hs recibirás el dinero en tu cuenta*.-->
    <!--                  </h3>-->
    <!--                  <p class="subtitle">-->
    <!--                    TE HEMOS ENVIADO UN MAIL DE BIENVENIDA.<br>-->
    <!--                    *DEPÓSITO SUJETO A VERIFICACIÓN DE DATOS.-->
    <!--                  </p>-->
    <!--                </div>-->
    <!--              </v-col>-->
    <!--            </v-row>-->
    <!--          </v-main>-->
    <!--        </v-form>-->
    <!--        <v-footer app>-->
    <!--          <v-row>-->
    <!--            <v-col-->
    <!--              align="center"-->
    <!--              justify="center"-->
    <!--            >-->
    <!--              <v-btn-->
    <!--                bottom-->
    <!--                depressed-->
    <!--                rounded-->
    <!--                class="btnAmarillo"-->
    <!--                @click="next()"-->
    <!--              >-->
    <!--                Finalizar-->
    <!--              </v-btn>-->
    <!--            </v-col>-->
    <!--          </v-row>-->

    <!--          <v-row>-->
    <!--            <v-col-->
    <!--              align="right"-->
    <!--              justify="right"-->
    <!--            >-->
    <!--              <v-btn-->
    <!--                bottom-->
    <!--                depressed-->
    <!--                rounded-->
    <!--                class="btnWppAyuda btn-help&#45;&#45;margin"-->
    <!--                @click="$store.state.redirectToWhastapp()"-->
    <!--              >-->
    <!--                Ayuda-->
    <!--                <v-img-->
    <!--                  class="imageWppAyuda"-->
    <!--                  src="@/assets/img/icon_whatsapp_ayuda.png"-->
    <!--                  alt="wpp"-->
    <!--                />-->
    <!--              </v-btn>-->
    <!--            </v-col>-->
    <!--          </v-row>-->
    <!--        </v-footer>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div v-else>-->
    <v-main>
      <v-row
        align="center"
        align-content="center"
      >
        <v-col
          cols="12"
          md="6"
          sm="6"
          align-self="center"
        >
          <v-img
            src="@/assets/img/yellow_pet_celebration.gif"
            alt="logo"
            class="step-finish__img"
            contain
          />
        </v-col>
        <v-col
          class="step-finish__text"
          cols="12"
          md="6"
          sm="6"
        >
          <h2>
            En las próximas 24hs recibirás <br>
            el dinero en tu cuenta*.
          </h2>
          <h5 class="subtitle">
            TE HEMOS ENVIADO UN MAIL DE BIENVENIDA.<br>
            *DEPÓSITO SUJETO A VERIFICACIÓN DE DATOS.
          </h5>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-md-end text-center col-md-6 offset-md-6">
          <v-btn
            bottom
            depressed
            rounded
            x-large
            class="btnAmarillo"
            @click="next()"
          >
            Finalizar
          </v-btn>
        </v-col>
      </v-row>
    </v-main>
  </div>
<!--  </div>-->
</template>

<script>
import { homeRoute } from "@/helper/homeRoute";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "AutogestionDatosSolicitudTerminada",
  data: () => ({
    valid: true,
  }),
  mounted() {
    this.setStyleStepper("solicitud__stepper-img");
    this.$gtm.trackEvent({
      noninteraction: false,
      event: `${this.getPreffix()}_CREDIT_ORDER`,
      id_person: this.$store.getters.getUserForm,
    });
  },
  methods: {
    ...mapGetters(["getPreffix"]),
    ...mapActions(["setStyleStepper"]),
    next() {
      this.$router.replace({ name: homeRoute.HOME });
    },
  }
};
</script>
<style>


</style>